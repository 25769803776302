.container {
  width: 100%;
  height: auto;
  min-height: 320px;
  background: #fbfefd;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}
.header {
  height: auto;
  min-height: 68px;
  width: 100%;
  padding: 30px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  & > h2 {
    font-size: 16px;
  }
}
.listItem {
  height: 55px;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  & > h2 {
    font-size: 16px;
    font-weight: 400;
  }
}
.menuContainer {
  width: 100%;
  height: 100%;
  & > .menuHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    width: 100%;
    padding: 0 26px;
    border-bottom: 1px solid #e5e9ec;
    & > h2 {
      font-weight: 500;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #4f4f4f;
    }
  }
  & > .menuFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 85px;
    width: 100%;
    padding: 0 26px;
    border-top: 1px solid #e5e9ec;
    display: flex;
    justify-content: center;
    align-items: center;
    & > button:last-child {
      margin-left: 20px;
      background-color: #27ae60;
      color: #fff;
    }
  }
  & > .menuContent {
    padding: 30px 26px;
    height: auto;
    & > div:not(:first-child) {
      margin-top: 20px;
    }
    & > div {
      & > span {
        font-style: normal;
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 16px;
        color: #333333;
      }
      & > div {
        width: 100%;
      }
    }
  }
}
.tableContent {
  height: 100%;
  width: 100%;
  max-height: 250px;
}
