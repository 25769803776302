.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  & > hr {
    background-color: #E0E0E0;
  }
}

.pageWrapper {
  margin-bottom: 4rem;

  & > hr {
    background-color: #E0E0E0;
  }
}

.sectionOne {
  padding: 0 2rem;

  & > div:nth-child(1) {
    margin: 1.25rem 0;
    div {
      display: flex;
      align-items: center;
      color: #828282;
      & > p {
        color: #4F4F4F;
        font-size: .875rem;
        font-weight: 400;
      }
    }
  };

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    margin-bottom: 1.5rem;

    & > p:nth-child(1) {
      color: #333;
      font-size: 1.5rem;
      font-weight: 700;
    };
    & > p:nth-child(2) {
      color: white;
      font-weight: 500;
      font-size: .75rem;
      padding: .15rem .625rem;
      background-color:  #27AE60;
      border-radius: 2px;
    };
  }  
}

.sectionTwo {
  padding: 2rem 2rem 0;
  display: flex;
  gap: 3rem;

  @media screen and (max-width: 1050px) {
    gap: 2rem;
  }

  @media screen and (max-width: 920px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 400px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  & > div:nth-child(even) {
    margin: .5rem 0;
    width: 1.5px;
    background: #E0E0E0;

    @media screen and (max-width: 920px) {
      display: none;
    }
  }

  & > div:nth-child(odd) {
    font-weight: 400;
    font-size: .875rem;
    & > p:nth-child(1) {
      color: #828282;
    }
    & > p:nth-child(2) {
      color: #333;
      margin-top: .5rem;
    }
  }
}

.sectionThree {
  padding: 4rem 2rem 0;

  @media screen and (max-width: 760px) {
    padding: 3rem 6rem 0 2rem;
  }

  & > div:nth-child(1) {
    border-bottom: 1px solid #E0E0E0;
    h3 {
      font-family: 'Roboto', sans-serif;
      color: #333;
      font-weight: 700;
      padding: .625rem 0;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    gap: 3rem;
    margin-top: .625rem;
    flex-wrap: wrap;

    @media screen and (max-width: 920px) {
      gap: 2rem;
    }

    @media screen and (max-width: 400px) {
      display: grid;
      grid-gap: 1rem;
    }

    & > div {
      font-weight: 400;
      font-size: .875rem;
      & > p:nth-child(1) {
        color: #828282;
      }
      & > p:nth-child(2) {
        color: #333;
        margin-top: .5rem;
      }
    }
  }
}

.sectionFour {
  padding: 4rem 2rem 0;

  & > div:nth-child(1) {
    border-bottom: 1px solid #E0E0E0;
    h3 {
      font-family: 'Roboto', sans-serif;
      color: #333;
      font-weight: 700;
      padding: .625rem 0;
    }
  }
}

.yellowText {
  color: #12122c;
  background-color: #f2c94c;
  text-transform: capitalize;
}

.greenText {
  color: white;
  background-color: #219653;
  text-transform: capitalize;
}

.redText {
  color: white;
  background-color: #eb5757;
  text-transform: capitalize;
}

.pendingText {
  font-size: .875rem;
  color: #12122c;
  font-weight: 500;
  cursor: pointer;
  padding: .2rem 1rem;
  background-color: #f2c94c;
  border-radius: 2px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
}

.wonText {
  font-size: .875rem;
  color: white;
  font-weight: 500;
  cursor: pointer;
  padding: .2rem 1rem;
  background-color: #219653;
  border-radius: 2px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
}

.lostText {
  font-size: .875rem;
  color: white;
  font-weight: 500;
  cursor: pointer;
  padding: .2rem 1rem;
  background-color: #eb5757;
  border-radius: 2px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
}

.tableContainer {
  margin-top: 1.5rem;
}


.tableBodyText {
  font-size: .875rem;
  color: #333;
  font-weight: 400;
  cursor: pointer;
}

.tableBodyCapital {
  font-size: .875rem;
  color: #333;
  font-weight: 400;
  cursor: pointer;
  text-transform: capitalize;
}

.tableBodySpan {
  color: #828282;
}

