.container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 269px);
  position: relative;

  @media screen and (max-width: 850px) {
		width: calc(100% - 73px);
	}

  @media screen and (max-width: 600px) {
		width: calc(100% - 57px);
	}

  & > hr {
    background-color: #E0E0E0;
  }
}

.pageWrapper {
  padding: 2rem;
  margin-bottom: 4rem;
}

.donationModalContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  padding: 1rem 2rem;

  & > div {
    display: grid;
    grid-gap: 1rem;
  }

  @media screen and (max-width: 850px) {
		grid-template-columns: 1fr;
	}
}

.capitalText {
  text-transform: capitalize;
}

.tableBodyFlex {
  display: flex;
  gap: .5rem;
  align-items: center;

  & > div {
    cursor: pointer;
  }

  & > div:last-of-type {
    svg {
      width: .85rem;
      height: .85rem;
      top: .25rem;
      position: relative;
    }
  }
}

.tableBodyText {
  font-size: .875rem;
  color: #333;
  font-weight: 400;
  cursor: pointer;
}

.tableBodySpan {
  color: #828282;
}

.tableContainer {
  margin-top: 1.5rem;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 570px) {
    flex-direction: column;
    align-items: flex-start;
    gap: .75rem;
  }

  & > div {
    & > p {
      color: #333;
      font-size: 1.25rem;
    }
  }
}

.filterModalContainer {
  border: 1px solid #D5DAE1;
  box-shadow: 0px 10px 10px rgba(6, 44, 82, 0.92);
  border-radius: 3px;
  background-color: white;
  max-width: 400px;
  max-height: 470px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Roboto', sans-serif;

  & > p {
    font-size: .875rem;
    color: #4F4F4F;
    margin: 1rem 1.5rem;
  }

  hr {
    background-color: #E5E9EC;
  }
}

.modalContent {
  margin: 2rem 1.5rem;
  display: grid;
  grid-gap: 1.25rem;

  & > div:nth-child(1) {
    & > p {
      color: #333;
      font-size: .875rem;
    }
    & > div {
      display: flex;
      margin-top: .5rem;
      gap: 1rem;

      & > p {
        border: 1px solid #DDDDDD;
        border-radius: 2px;
        padding: .375rem .75rem;
        color: #828282;
        font-size: .875rem;
      }

      & > p:hover {
        cursor: pointer;
        opacity: .75;
      }
    }
  }

  & > div:nth-child(2) {
    & > p {
      color: #333;
      font-size: .875rem;
    }
    & > div {
      display: flex;
      gap: 1rem;
      align-items: center;

      & > svg {
        color: #25303A;
        margin-top: .5rem;
      }

      & > div {
        border: 1px solid #DDDDDD;
        border-radius: 2px;
        padding: .375rem .75rem;
        color: #C4C4C4;
        flex: 1;
        font-size: .75rem;
        margin-top: .5rem;
      }
    }
  }

  & > div:nth-child(3) {
    & > p {
      color: #333;
      font-size: .875rem;
      margin-bottom: .5rem;
    }
  }

  & > div:nth-child(4) {
    & > p {
      color: #333;
      font-size: .875rem;
      margin-bottom: .5rem;
    }
  }
}

