.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  & > hr {
    background-color: #E0E0E0;
  }
}

.pageWrapper {
  padding: 1rem 2rem;
  margin-bottom: 4rem;

  @media screen and (max-width: 770px) {
    padding-right: 6rem;
  }
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 570px) {
    flex-direction: column;
    align-items: flex-start;
    gap: .75rem;
  }

  & > div {
    & > p {
      color: #333;
      font-size: 1.25rem;
    }
  }
}

.tableContainer {
  margin-top: 1.5rem;
}

.tableBodySpan {
  color: #828282;
}

.blacklistFlex {
  background: #F5FFFA;
  border: 1px solid #F0F0F0;
  margin-top: 4rem;
  border-radius: .25rem;
  display: flex;
  padding: 3rem 6rem;
  justify-content: space-between;

  @media screen and (max-width: 1000px) {
    padding: 3rem 4rem;
  }

  @media screen and (max-width: 570px) {
    flex-direction: column;
    gap: 1.5rem;
  }

  & > div:nth-child(even) {
    width: 1.5px;
    background: #DFE7EF;
  }

  & > div:nth-child(odd) {
    display: grid;
    place-items: center;

    svg {
      color: #4F4F4F;
      margin-bottom: 1.25rem;
      @media screen and (max-width: 570px) {
        margin-bottom: 0rem;
      }
    }

    & > p {
      color: #4F4F4F;
      font-size: .875rem;
      font-weight: 400;
      margin-top: .5rem;
      @media screen and (max-width: 570px) {
        margin-top: .25rem;
      }
    }

    & > p:first-of-type {
      font-weight: 500;
      font-size: 1rem;
      color: #333;
    }

    & > p:last-of-type {
      color: #219653;
      text-decoration: underline;
      cursor: pointer;
    }

    & > p:last-of-type:hover {
      opacity: .75;
    }
  }
}

.capitalText {
  text-transform: capitalize;
}

.tableBodyText {
  font-size: .875rem;
  color: #333;
  font-weight: 400;
  cursor: pointer;
}