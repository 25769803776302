.container {
  background: #fbfefd;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  height: auto;
  min-height: 89px;
  width: 95%;
  margin: 20px auto auto;
  padding: 20px 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  & > div:not(:first-child) {
    padding-left: 60px;
  }
  & > div:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.14);
  }
}
