.container {
	background-color: #fff;
	height: 85px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 30px;
	border-bottom: 1px solid #e0e0e0;
}
.searchContainer {
	width: 542px;
}

.flex {
	display: flex;
	align-items: center;
}

.headerButton {
	background-color: transparent;
	border-radius: 4px;
	border: 1px solid rgba(243, 44, 0, 1);
	color: rgba(243, 44, 0, 1);
	padding: 8px 20px;
	cursor: none;
	margin-right: 10px;
  width: 120px;

	// @media screen and (max-width: 768px) {
	// 	padding: 3px 5px;
	// }
}


