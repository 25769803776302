.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	margin-bottom: 50px;
}
.button_business_button {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #27ae60;
	border-radius: 4px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.0024px;
	color: #ffffff;
	border: none;
	outline: none;
	width: 131px;
	height: 32px;
	cursor: pointer;
}

.wrapper {
	width: 95%;
	margin: 20px auto auto;
	& > .header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		& > div {
			display: flex;
			align-items: center;
			& > button {
				display: flex;
				align-items: center;
				padding: 8px 14px;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: 0.0024px;
				color: #333333;
				width: auto;
				min-width: fit-content;
				height: 31px;
				background: #e0e0e0;
				border-radius: 4px;
				& > svg {
					font-size: 15px;
					margin-left: 5px;
				}
				&:first-child {
					& > svg {
						font-size: 20px;
					}
				}
				&:last-child {
					background: #27ae60;
					color: #fff;
					margin-left: 16px;
				}
			}
		}
	}
}
.menuContainer {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 57px 1fr 80px;

	& > .menuHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 26px;
		border-bottom: 1px solid #e5e9ec;
		& > h2 {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			color: #4f4f4f;
		}
	}
	& > .menuContent {
		width: 100%;
		height: 100%;
		min-height: 430px;
		padding: 32px 26px;
		& > .dateRange {
			display: flex;
			flex-direction: column;
			margin-bottom: 22px;
			& > h2 {
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				color: #333333;
				margin-bottom: 8px;
			}
			& > .dateInputRange {
				width: 100%;
				display: grid !important;
				grid-column-gap: 14px;
				grid-template-columns: 150px 1fr 150px;
				align-items: center;
				justify-content: center;
			}
			& > .fluid {
				width: 100%;
				& > div {
					width: 100%;
				}
			}
			& > div {
				display: flex;
				height: 100%;
				width: fit-content;
				& > button {
					background: #ffffff;
					border: 1px solid #dddddd;
					border-radius: 2px;
					font-family: Roboto;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 20px;
					display: flex;
					align-items: center;
					text-align: center;
					letter-spacing: 0.00312px;
					color: #828282;
					white-space: nowrap;
					padding: 6px 12px;
					&:focus {
						border: 1px solid #27ae60;
						color: #27ae60;
					}
					&:not(:last-child) {
						margin-right: 14px;
					}
				}
			}
		}
	}
	& > .menuFooter {
		border-top: 1px solid #e5e9ec;
		width: 100%;
		height: 100%;
		padding: 26px 36px;
		display: flex;
		justify-content: right;
		align-items: center;
		& > button:last-child {
			margin-left: 20px;
			background-color: #27ae60;
			color: #fff;
		}
	}
}
.amount {
	display: flex;
	align-items: center;
	& > span {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #828282;
		margin-right: 8px;
	}
	& > h2 {
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 16px;
		color: #333333;
	}
}

.containerNew {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.header {
	width: 100%;
	margin: 0 auto;
	margin-top: 20px;
	& > h2 {
		font-family: Roboto;
		font-style: normal;
		font-weight: lighter;
		font-size: 30px;
		line-height: 35px;
		color: #333333;
	}
	& > p {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		margin-top: 25px;
		color: #828282;
	}
}
.panel {
	background: #f8f8f8;
	border: 1px solid #f0f0f0;
	box-sizing: border-box;
	border-radius: 4px;
	width: 841px;
	height: 274px;
	margin: 65px auto auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	place-items: center;
	padding: 50px 0;
	& > div {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		& > h2 {
			font-style: normal;
			font-weight: 600;
			width: 190px;
			font-size: 14px;
			line-height: 16px;
			text-align: center;
			margin-top: 20px;
			color: #333333;
		}
		& > button {
			margin-top: 24px !important;
		}
	}
	& > div:last-child {
		border-left: 1px solid #dfe7ef;
	}
}
button.primary {
	background: #27ae60 !important;
	border-radius: 4px;
	color: #fff !important;
	opacity: 1;
	transition: all 0.4s ease;
}
button.primary:hover {
	opacity: 0.9;
}
