.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  & > hr {
    background-color: #E0E0E0;
  }
}

.pageWrapper {
  padding: 2rem;
  margin-bottom: 4rem;
}

.balanceContainer {
  display: grid;
  grid-gap: 1rem;
}

.topBoxContainer {
  background: #F9F9F9;
  max-width: 900px;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 792px) {
    max-width: max-content;
    padding: 1rem;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    max-width: fit-content;
    margin-right: 3rem;
    gap: 1rem;
  }

  & > div {
    flex: 1; 

    & > p:nth-child(1) {
      color: #828282;
      font-size: .875rem;
      font-weight: 400;
    }
  
    & > p:nth-child(2) {
      color: black;
      font-size: 1rem;
      font-weight: 500;
      margin: .625rem 0 .5rem;

      @media screen and (max-width: 700px) {
        margin: .25rem 0;
      }
    }

    & > a {
      color: #27ae60;
      font-size: .875rem;
      font-weight: 400;
      text-decoration: underline;
    }

    & > div {
      display: flex;
      color: #828282;
      font-weight: 400;
      font-size: .75rem;

      & > svg {
        font-size: .75rem;
        margin-top: .1rem;
      }
    }
  }
}

.bottomBoxContainer {
  margin-top: 2.5rem;
  max-width: 900px;

  @media screen and (max-width: 792px) {
    max-width: 500px;
    width: 100%;
  }

  @media screen and (max-width: 700px) {
    max-width: fit-content;
    margin-right: 3rem;
  }

  & > hr {
    background: #E0E0E0;
    margin-bottom: 1.5rem;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @media screen and (max-width: 700px) {
      gap: 2rem;
    }

    h3 {
      font-weight: 700;
      font-size: 1.375rem;
      color: #333;
      font-family: 'Roboto', sans-serif;

      @media screen and (max-width: 700px) {
        font-size: 1.15rem;
      }
    }

    & > div {
      display: flex;

      & > p {
        font-size: 1rem;
        font-weight: 400;
        color: #4F4F4F;
      }

      & > button {
        font-size: .875rem;
        font-weight: 500;
        color: #27ae60;
        border: none;
        background-color: transparent;
      }

      & > button:hover {
        opacity: .65;
      }
    }
  }

}

.historyTopContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterModalContainer {
  border: 1px solid #D5DAE1;
  box-shadow: 0px 10px 10px rgba(6, 44, 82, 0.92);
  border-radius: 3px;
  background-color: white;
  max-width: 400px;
  max-height: 470px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Roboto', sans-serif;

  & > p {
    font-size: .875rem;
    color: #4F4F4F;
    margin: 1rem 1.5rem;
  }

  hr {
    background-color: #E5E9EC;
  }
}

.modalContent {
  margin: 2rem 1.5rem;
  display: grid;
  grid-gap: 1.25rem;

  & > div:nth-child(1) {
    & > p {
      color: #333;
      font-size: .875rem;
    }
    & > div {
      display: flex;
      margin-top: .5rem;
      gap: 1rem;

      & > p {
        border: 1px solid #DDDDDD;
        border-radius: 2px;
        padding: .375rem .75rem;
        color: #828282;
        font-size: .875rem;
      }

      & > p:hover {
        cursor: pointer;
        opacity: .75;
      }
    }
  }

  & > div:nth-child(2) {
    & > p {
      color: #333;
      font-size: .875rem;
    }
    & > div {
      display: flex;
      gap: 1rem;
      align-items: center;

      & > svg {
        color: #25303A;
        margin-top: .5rem;
      }

      & > div {
        border: 1px solid #DDDDDD;
        border-radius: 2px;
        padding: .375rem .75rem;
        color: #C4C4C4;
        flex: 1;
        font-size: .75rem;
        margin-top: .5rem;
      }
    }
  }

  & > div:nth-child(3) {
    & > p {
      color: #333;
      font-size: .875rem;
      margin-bottom: .5rem;
    }
  }

  & > div:nth-child(4) {
    & > p {
      color: #333;
      font-size: .875rem;
      margin-bottom: .5rem;
    }
  }
}

.pendingText {
  font-size: .875rem;
  color: #12122c;
  font-weight: 500;
  cursor: pointer;
  padding: .2rem 1rem;
  background-color: #f2c94c;
  border-radius: 2px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
}

.wonText {
  font-size: .875rem;
  color: white;
  font-weight: 500;
  cursor: pointer;
  padding: .2rem 1rem;
  background-color: #27ae60;
  border-radius: 2px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
}

.lostText {
  font-size: .875rem;
  color: white;
  font-weight: 500;
  cursor: pointer;
  padding: .2rem 1rem;
  background-color: #eb5757;
  border-radius: 2px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
}

.tableContainer {
  margin-top: 1rem;
}

.tableBodySpan {
  color: #828282;
}

.tableBodyText {
  font-size: .875rem;
  color: #333;
  font-weight: 400;
  cursor: pointer;
}

.tableBodyCapital {
  font-size: .875rem;
  color: #333;
  font-weight: 400;
  cursor: pointer;
  text-transform: capitalize;
}
