.menuContainer {
	display: flex;
	align-items: center;
	justify-content: right;
	width: 100%;
	height: 100%;
}
.menuIcon {
	color: #bdbdbd;
}
.iconGroup {
	display: flex;
	align-items: center;
}
.account {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
}
.account > div:first-child {
	display: flex;
	align-items: center;
	margin-right: 8px;
}
.account > div:last-child {
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	color: rgba(0, 40, 65, 0.8);
}
.username {
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	color: rgba(0, 40, 65, 0.8);
}
.signOut {
	color: #f71735 !important;
}

.switchtoggle {
	display: flex;
	align-items: center;
}

.switchtoggle_p {
	margin: 0 10px;
}
