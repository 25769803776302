.signupContainer {
	min-height: 100vh;
	min-width: 100%;
	background-color: rgba(239, 243, 248, 1);
	margin: 0 auto;
}

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 2rem 0 2rem 0;
}

.accountTypes {
	display: flex;
	align-items: flex-start;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	margin: 1rem;
	padding: 1rem;
}

.signupDiv {
	display: flex;
	border: 1px solid transparent;
	width: 800px;
	height: auto;
	margin: 0 auto;
	background-color: #fff;
	padding: 2rem;
	background: #ffffff;
	box-shadow: 0px 4px 14px rgba(15, 49, 32, 0.15);
	border-radius: 6px;
	align-items: center;
	justify-content: center;
}

.signUpContent {
	width: 500px;
	height: 40%;
	padding: 0;
}

.signupList {
	margin: 20px 0px 0px 20px;
}

.signupHeader {
	display: flex;
	justify-content: flex-start;
	margin: 0 auto 1rem auto;
	font-weight: normal;
	font-size: 22px;
	color: #4f4f4f;
	width: 80%;
}

.desc {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #8b8b8b;
	margin-top: 1rem;
}

.title {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: #414141;
	margin-top: 0;
}

.formTitle {
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	color: #414141;
}

.mt1 {
	margin-top: 1rem;
	cursor: pointer;
}

.mt2 {
	margin-top: 2rem;
}

.sub {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 30px;
}

.subP {
	font-style: normal;
	font-weight: normal;
	font-size: 0.9rem;
	line-height: 1rem;
	text-align: center;
	color: #6f6f6f;
}

.formSub {
	margin-top: 2rem;
	font-size: 0.9rem;
	line-height: 1rem;
	text-align: center;
	color: #000000;
}

.divRadioGroup {
	display: flex;
	justify-content: flex-start;
	border: 1px solid #e0e0e0;
	margin: 0.5rem auto;
	padding: 1rem;
	box-sizing: border-box;
	border-radius: 4px;
	width: 80%;
}

.divRadioGroup:focus {
	border: 1px solid #27ae60;
}

.divRadioGroup:hover {
	border: 1px solid #27ae60;
}

.ml {
	margin-left: 1rem;
}

.buttonDiv {
	text-align: center;
	margin: 2rem 0;
}

@media only screen and (max-width: 600px) {
	.signupHeader {
		font-size: 16px;
		text-align: center;
	}

	.signupDiv {
		padding: 0.5rem;
		width: 90%;
	}
}
