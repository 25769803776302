.container {
	width: 100%;
	min-height: 100vh;
}

.navbar {
	border-bottom: 1px solid rgba(224, 224, 224, 1);
	padding: 2rem;

	@media screen and (max-width: 768px) {
		padding: 1rem;
	}
}

.navbarTitle {
	padding: 0.7rem;

	@media screen and (max-width: 768px) {
		display: none;
	}
}

.buttonWrapper {
	@media screen {
		margin-top: 0.8rem;
	}
}

.headerButton {
	background-color: transparent;
	border-radius: 4px;
	border: 1px solid rgba(243, 44, 0, 1);
	color: rgba(243, 44, 0, 1);
	padding: 8px 12px;
	cursor: none;

	@media screen and (max-width: 768px) {
		padding: 3px 5px;
	}
}

.bell {
	padding-right: 1rem;
}

.mt1 {
	margin-top: 1.5rem;
}

.mainContent {
	width: 75%;
	padding-top: 4rem;

	@media screen and(max-width : 768px) {
		width: 90%;
		padding-top: 1rem;
	}
}

.mainContentReview {
	width: 75%;
	padding-top: 10rem;

	@media screen and(max-width : 768px) {
		width: 90%;
		padding-top: 5rem;
	}
}

.content {
	width: 75%;

	p {
		padding-top: 0.5rem;
		color: rgba(130, 130, 130, 1);
	}

	@media screen and (max-width: 768px) {
		width: 100%;
	}
}

.contentReview {
	width: 75%;

	h2 {
		font-size: 50px;
		line-height: 50px;
	}

	p {
		font-size: 25px;
		padding-top: 0.5rem;
		color: rgba(130, 130, 130, 1);
	}

	@media screen and (max-width: 768px) {
		width: 100%;
	}
}

.contentWrapper {
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	padding: 1rem;
	width: 266px;

	@media screen and (max-width: 768px) {
		padding: 0.5rem;
		//height: 174px;
		width: 100%;
	}
}

.button {
	background: rgba(39, 174, 96, 1);
	color: white;
	padding: 8px 15px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	// padding: 0;
	width: 800px;
	// padding: 2rem;
	flex-wrap: wrap;

	//background-color: black;
	@media screen and (max-width: 1024px) {
		flex-direction: column;
		width: 100%;
		margin: 0 auto;
	}
}

.list {
	border-right: 1px solid rgba(223, 231, 239, 1);

	&:nth-child(3) {
		border-right: none;
		border-bottom: none;
	}

	@media screen and (max-width: 768px) {
		border-right: none;
		border-bottom: 1px solid rgba(130, 130, 130, 1);
		width: 100%;
	}
}

.box {
	// width: 100%;
	width: 820px;

	@media screen and (max-width: 1024px) {
		flex-direction: column;
		justify-content: center;
		width: 100%;
	}
}

.modalDiv {
	display: flex;
	margin: 0 auto;
}

.button_key {
	color: #fff;
	background-color: #27ae60;
	border-radius: 4px;
	border: none;
	padding: 0.5rem;
	width: 80%;
	cursor: pointer;

	&:disabled {
		pointer-events: none;
		opacity: 0.3;
	}
}
// .container {

//     width: 100%;
//     min-height: 100vh;
//    // background-color: red;
//   }
//   .navbar {
//     border-bottom: 1px solid rgba(224, 224, 224, 1);
//     padding: 2rem;
//     @media screen and (max-width: 768px) {
//       padding: 1rem;
//     }
//   }
//   .navbarTitle {
//     padding: 0.7rem;
//     @media screen and (max-width: 768px) {
//       display: none;
//     }
//   }
//   .buttonWrapper {
//     @media screen {
//       margin-top : 0.8rem;
//     }

//   }
//   .headerButton {
//     background-color: transparent;
//     border-radius: 4px;
//     border : 1px solid rgba(243, 44, 0, 1);
//     color : rgba(243, 44, 0, 1);
//      padding : 8px 12px;
//     cursor : none;
//     @media screen and (max-width : 768px){
//     padding : 3px 5px;
//     }
//   }
//   .bell {
//     padding-right: 1rem;
//   }
//   .mainContent {
//     width: 100%;
//     padding-top : 4rem;
//     @media screen and(max-width : 768px) {
//       width: 90%;
//       padding-top: 1rem;
//     }
//   }
//   .content {
//     width: 75%;
//     margin : 0 auto;
//     p {
//       padding-top: 0.5rem;
//       color: rgba(130, 130, 130, 1);
//     }
//     @media screen and (max-width : 768px){
//       width : 100%;
//     }
//   }
//   .contentWrapper {
//     height: 150px;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     text-align: center;
//     padding: 1rem;
//     //width: 200px;
//     //width : 100%;
//     width: 250px;
//    // max-width: 400px;

//     @media screen and (max-width: 768px) {
//        padding : 0.5rem;
//      //height: 174px;
//      width : 100%;
//     }
//   width: 100%;
//   min-height: 100vh;
// }

// .navbar {
//   border-bottom: 1px solid rgba(224, 224, 224, 1);
//   padding: 2rem;

//   @media screen and (max-width: 768px) {
//     padding: 1rem;
//   }
// }

// .navbarTitle {
//   padding: 0.7rem;

//   @media screen and (max-width: 768px) {
//     display: none;
//   }

//   .wrapper {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 0;

//     // max-width : 700px;
//     // width : 90%;
//     //background-color: black;
//     @media screen and (max-width: 768px) {
//       flex-direction: column;
//       width: 100%;
//       margin: 0 auto;
//     }
// }

// .buttonWrapper {
//   @media screen {
//     margin-top: 0.8rem;
//   }

// }

// .headerButton {
//   background-color: transparent;
//   border-radius: 4px;
//   border: 1px solid rgba(243, 44, 0, 1);
//   color: rgba(243, 44, 0, 1);
//   padding: 8px 12px;
//   cursor: none;

//   @media screen and (max-width : 768px) {
//     padding: 3px 5px;
//   }
// <<<<<<< HEAD
//   .box {
//     //background-color: red;
//     width : 75%;
//     max-width : 1200px;
//     margin : 0 auto;
//    //  max-width: 900px;
//     @media screen and (max-width: 768px) {
//       flex-direction: column;
//       justify-content: center;
//     }
// =======
// }

// .bell {
//   padding-right: 1rem;
// }

// .mt1{
//   margin-top: 1.5rem;
// }

// .mainContent {
//   width: 75%;
//   padding-top: 4rem;

//   @media screen and(max-width : 768px) {
//     width: 90%;
//     padding-top: 1rem;
// >>>>>>> 4bb3e552c220ba2acb7e63846256ba4da24d848b
//   }
// }

// .content {
//   width: 75%;

//   p {
//     padding-top: 0.5rem;
//     color: rgba(130, 130, 130, 1);
//   }

//   @media screen and (max-width : 768px) {
//     width: 100%;
//   }
// }

// .contentWrapper {
//   height: 200px;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   text-align: center;
//   padding: 1rem;
//   width: 266px;

//   @media screen and (max-width: 768px) {
//     padding: 0.5rem;
//     //height: 174px;
//     width: 100%;
//   }
// }

// .button {
//   background: rgba(39, 174, 96, 1);
//   color: white;
//   padding: 8px 15px;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
// }

// .wrapper {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   // padding: 0;
//   width: 800px;
//   // padding: 2rem;

//   //background-color: black;
//   @media screen and (max-width: 1024px) {
//     flex-direction: column;
//     width: 100%;
//     margin: 0 auto;
//   }
// }

// .list {
//   border-right: 1px solid rgba(223, 231, 239, 1);

//   &:nth-child(3) {
//     border-right: none;
//     border-bottom: none;
//   }

//   @media screen and (max-width: 768px) {
//     border-right: none;
//     border-bottom: 1px solid rgba(130, 130, 130, 1);
//     width: 100%;
//   }
// }

// .box {
//   // width: 100%;
//   width: 820px;

//   @media screen and (max-width: 1024px) {
//     flex-direction: column;
//     justify-content: center;
//     width: 100%
//   }
// }

// .modalDiv{
//   display: flex;
//   margin: 0 auto;
// }
