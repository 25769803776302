.onboarding_form_container{
  width: auto;
  min-width: 30rem;
}
.form__select_type{
  color: #333;
  font-weight: 500;
  font-family: Roboto, "Arial, Helvetica, sans-serif";
}
.form__input {
  font-size: 1.5rem;
  font-family: inherit;
  color: inherit;
  padding: 1.5rem 2rem;
  border-radius: 2px;
  background-color: rbga(#fff, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}

.form__label {
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}

.form__group {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  padding: 1.5rem;
}
.form__select-type {
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
}
.form__radio-group {
  width: 100%;
  max-width: 450px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(224, 224, 224, 1);
  margin: 10px auto;
  /* padding: 10px 10px 0px 10px; */
  border-radius: 4px;
  height: auto;
  min-height: 6rem;
}

.form__radio-input {
  display: none;
}

.form__radio-label {
  font-size: 1.6rem;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-left: 2rem; */
}

.form__radio-button {
  height: 1rem;
  width: 1rem;
  border: 5px solid #55c57a;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.form__radio-button::after {
  content: "";
  display: block;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #55c57a;
  opacity: 0;
  transition: opacity 0.2s;
}

.form__radio-button1 {
  height: 1.5rem;
  width: 1.5rem;
  border: 5px solid #55c57a;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 12%;
  top: 5%;
}

.form__radio-button1::after {
  content: "";
  display: block;
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #55c57a;
  opacity: 0;
  transition: opacity 0.2s;
}

.form__radio-input:checked ~ .form__radio-label .form__radio-button::after {
  opacity: 1;
}

.form__radio-input:checked ~ .form__radio-label .form__radio-button1::after {
  opacity: 1;
}

.content {
  /* padding: 0 2rem; */
  width: 100%;
  height: 100%;
  margin-left: 5rem;
}

.content_h1 {
  font-size: 2rem;
  padding: 0;
  margin: 0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: #333;
}

.content_p {
  margin-top: 5px;
  font-size: 1.4rem;
  width: 100%;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: rgba(139, 139, 139, 1);
}
/* .active {
	border: 1px solid #27ae60;
} */
.notActive {
  border: 1px solid rgba(224, 224, 224, 1);
}
