.container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 269px);
  position: relative;

  @media screen and (max-width: 850px) {
		width: calc(100% - 73px);
	}

  @media screen and (max-width: 600px) {
		width: calc(100% - 57px);
	}

  hr {
    background-color: #E0E0E0;
  }
}

.pageWrapper {
  margin-bottom: 4rem;
}

.yellowText {
  color: #12122c;
  background-color: #f2c94c;
  text-transform: capitalize;
}

.greenText {
  color: white;
  background-color: #219653;
  text-transform: capitalize;
}

.redText {
  color: white;
  background-color: #eb5757;
  text-transform: capitalize;
}

.pendingText {
  font-size: .875rem;
  color: #12122c;
  font-weight: 500;
  cursor: pointer;
  padding: .2rem 1rem;
  background-color: #f2c94c;
  border-radius: 2px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
}

.wonText {
  font-size: .875rem;
  color: white;
  font-weight: 500;
  cursor: pointer;
  padding: .2rem 1rem;
  background-color: #219653;
  border-radius: 2px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
}

.lostText {
  font-size: .875rem;
  color: white;
  font-weight: 500;
  cursor: pointer;
  padding: .2rem 1rem;
  background-color: #eb5757;
  border-radius: 2px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
}

.sectionOne {
  padding: 0 2rem 2rem;
  border-bottom: 1px solid #e0e0e0;

  & > div {
    margin: 1.25rem 0;
    div {
      display: flex;
      align-items: center;
      color: #828282;
      & > p {
        color: #4F4F4F;
        font-size: .875rem;
        font-weight: 400;
      }
    }
  };

  & > p {
    font-size: .875rem;
    border-radius: 2px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    padding: .2rem 2rem;
    display: inline-block;
  }
}

.sectionTwo {
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 6rem;
  flex-wrap: wrap;

  @media screen and (max-width: 1100px) {
    gap: 4rem;
  }

  @media screen and (max-width: 768px) {
    gap: 1.25rem;
  }

  & > div {
    border-left: 1px solid #e0e0e0;
    padding-left: 6rem;
    @media screen and (max-width: 1100px) {
      padding-left: 4rem;
    }
    @media screen and (max-width: 950px) {
      border-left: none;
      padding-left: 0;
    }

    & > p:first-of-type {
      color: #828282;
      font-size: .75rem;
      font-weight: 400;
    }

    & > p:last-of-type {
      color: #333;
      font-size: .875rem;
      font-weight: 500;
      margin-top: .5rem;
    }
  }

  & > div:first-of-type {
    border-left: none;
    padding-left: 0;
  }
}

.sectionThree {
  padding: 4rem 2rem;

  h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.tableContainer {
  margin-top: 1rem;
}


.tableBodyText {
  font-size: .875rem;
  color: #333;
  font-weight: 400;
  cursor: pointer;
}

.tableBodyCapital {
  font-size: .875rem;
  color: #333;
  font-weight: 400;
  cursor: pointer;
  text-transform: capitalize;
}