@import url("https://font.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

s * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: GeometriaLight, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.check {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
iframe {
  display: none;
}
button {
  font-family: "Roboto", sans-serif !important;
}
.success {
  background-color: #27ae60 !important;
  color: #fff !important;
}
.warning {
  background-color: #f2c94c !important;
  color: #12122c !important;
}
.danger {
  background-color: #eb5757 !important;
  color: #fff !important;
}
button.success {
  width: auto;
  height: auto;
  max-height: 50px;
  max-width: fit-content !important;
  opacity: 1;
  transition: all 0.4s ease;
  background-color: #27ae60;
  color: #fff;
}
button:hover {
  opacity: 0.9;
}
.ui.page.modals {
  z-index: 9999;
}
.text-success {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #27ae60;
}
.action.text-success > span {
  font-size: 14px;
  color: #27ae60;
}
.text-danger {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #eb5757 !important;
}
.action.text-danger > span {
  font-size: 14px;
  color: #eb5757;
}
.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  background: #ffffff;
  border: 1.5px solid #27ae60;
  color: #27ae60 !important;
  border-radius: 2px;
}
.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
  background: #27ae60;
  border: 1.5px solid #27ae60;
}
button.active{
  color: #27ae60 !important;
  border: 1px solid #27ae60 !important;
}