.container {
	min-height: 100vh;
	background-color: rgba(239, 243, 248, 1);
	margin: 0 auto;
	width: 100%;
}
.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 3rem 0;
	cursor: pointer;
}
.signUpWrapper {
	border: 1px solid transparent;
	max-width: 950px;
	width: 100%;
	margin: 0 auto;
	background-color: #fff;
	padding: 3rem 4rem 2rem 4rem;
	background: #ffffff;
	box-shadow: 0px 4px 14px rgba(15, 49, 32, 0.15);
	border-radius: 6px;
}
.header {
	font-style: normal;
	font-weight: 300;
	font-size: 30px;
	line-height: 35px;
	color: #333333;
	margin: 0;
	padding: 0;
	margin-bottom: 1.5rem;
}

.details {
	margin-left: -10px;
}
.content {
	display: flex;
	width: 50%;
	@media screen and (max-width: 768px) {
		width: 100%;
	}
}
.title {
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0em;
}

.description {
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	color: rgba(139, 139, 139, 1);
}
.label {
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	color: rgba(51, 51, 51, 1);
}
.emptyLabel {
	margin-top: 2rem;
}
.terms {
	margin-top: 1rem;
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	color: rgba(139, 139, 139, 1);
}

.signupList {
	display: flex;
	align-items: flex-start;
}

.iconchecked {
	margin-top: 6px;
	padding: 0;
}

.desc {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	overflow: hidden;
	width: 75%;
	color: #8b8b8b;
	padding: 0;
	margin: 0;
	// line-break: auto;

	font-family: Roboto;
}

.title {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: #414141;
	padding: 0;
	margin: 0;
	margin: 6px 0;
}

.mt1 {
	margin-top: 1rem;
	cursor: pointer;
}

.sub {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 30px;
}

.subP {
	color: #8b8b8b;
}
