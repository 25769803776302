.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.wrapper {
  width: 100%;
  margin: 20px auto auto;
  & > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    & > div {
      display: flex;
      align-items: center;
      & > button {
        display: flex;
        align-items: center;
        padding: 8px 14px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.0024px;
        color: #333333;
        width: auto;
        min-width: fit-content;
        height: 31px;
        background: #e0e0e0;
        border-radius: 4px;
        & > svg {
          font-size: 15px;
          margin-left: 5px;
        }
        &:first-child {
          & > svg {
            font-size: 20px;
          }
        }
        &:last-child {
          background: #27ae60;
          color: #fff;
          margin-left: 16px;
        }
      }
    }
  }
}
.menuContainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 57px 1fr 80px;

  & > .menuHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px;
    border-bottom: 1px solid #e5e9ec;
    & > h2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #4f4f4f;
    }
  }
  & > .menuContent {
    width: 100%;
    height: 100%;
    min-height: 430px;
    padding: 32px 26px;
    & > .dateRange {
      display: flex;
      flex-direction: column;
      margin-bottom: 22px;
      & > h2 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #333333;
        margin-bottom: 8px;
      }
      & > .dateInputRange {
        width: 100%;
        display: grid !important;
        grid-column-gap: 14px;
        grid-template-columns: 150px 1fr 150px;
        align-items: center;
        justify-content: center;
      }
      & > .fluid {
        width: 100%;
        & > div {
          width: 100%;
        }
      }
      & > div {
        display: flex;
        height: 100%;
        width: fit-content;
        & > button {
          background: #ffffff;
          border: 1px solid #dddddd;
          border-radius: 2px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.00312px;
          color: #828282;
          white-space: nowrap;
          padding: 6px 12px;
          &:focus {
            border: 1px solid #27ae60;
            color: #27ae60;
          }
          &:not(:last-child) {
            margin-right: 14px;
          }
        }
      }
    }
  }
  & > .menuFooter {
    border-top: 1px solid #e5e9ec;
    width: 100%;
    height: 100%;
    padding: 26px 36px;
    display: flex;
    justify-content: right;
    align-items: center;
    & > button:last-child {
      margin-left: 20px;
      background-color: #27ae60;
      color: #fff;
    }
  }
}
.amount {
  display: flex;
  & > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
  }
  & > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
    color: #333333;
  }
}
.date {
  display: flex;
  align-items: center;
  justify-content: end;
  & > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
    margin-left: 4px;
  }
  & > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
  }
}
