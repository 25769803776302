.container {
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 900px;
  height: auto;
  padding: 100px 80px;
  & > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    color: #333333;
  }
  & > p {
    margin-top: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 19px;
    color: #828282;
  }
  & > button {
    margin-top: 34px !important;
  }
}
.menuOptions {
  margin-top: 16px !important;
}
.modalContainer {
  position: relative;
  z-index: 9999 !important;
  max-width: 419px !important;
  height: auto;
  min-height: 442px;
  background: #ffffff;
  box-shadow: -4px 4px 14px rgba(224, 224, 224, 0.69);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  & > div:nth-child(2) {
    margin-top: 26px;
  }
  & > p {
    padding: 0 40px;
    margin-bottom: 24px;
    font-family: Roboto;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(74, 82, 106);
    display: flex;
    align-items: center;
    & > svg {
      color: #27ae60;
      margin-right: 5px;
    }
    & > span {
      color: #414141;
      font-weight: 500;
      margin: 0 5px;
    }
  }
  & > .modalFooter {
    padding: 0 40px;
    margin-bottom: 24px;
    & > button {
      width: 100%;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      background: #27ae60;
      height: 44px;
      transition: all 0.4s ease;
      &:hover {
        color: #ffffff;
        background: #1e9751;
      }
      &:active {
        color: #ffffff;
        background: #1e9751;
        transform: scale(0.9);
      }
    }
  }
}
.modalHeader {
  display: flex;
  width: 100%;
  height: 75px;
  padding: 0 40px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  & > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #828282;
  }
}
.inputWrapper {
  padding: 0 40px;
  margin-bottom: 26px;
  display: flex;
  flex-direction: column;
  & > label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
    margin-bottom: 8px;
  }
  & > input,
  select {
    width: 100%;
    height: 44px;
    background: #ffffff;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #414141;
    padding: 0 15px;
  }
}
.inputDivider {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 40px;
  margin-bottom: 24px;
  & > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
  }
  & > a {
    font-weight: normal;
    font-size: 14px;
  }
}
.inputDesc {
  padding: 0 40px 26px 40px;
  border-bottom: 1px solid #e0e0e0;
  height: auto;
  margin-bottom: 2rem;
}
.inputDesc > p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 16px;
  color: #4f4f4f !important;
  margin-bottom: 20px;
}
.inputDesc > p > span {
  color: #333333;
  font-weight: 600;
  margin: 0 2px;
}
