.tableContainer {
  padding: 25px 56px;
  .tableHeader {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    & > h2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #333333;
    }
    & > div {
      display: flex;
      align-items: center;
      & > button {
        padding: 8px 16px;
        width: auto;
        min-width: fit-content;
        height: 32px;
        left: 857px;
        top: 0px;
        background: #e0e0e0;
        border-radius: 4px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.0024px;
        color: #333333;
        margin: 0px 4px;
        display: flex;
        align-items: center;
        & > svg {
          width: 15px;
          margin-left: 5px;
        }
        &:last-child {
          background: #27ae60;
          color: #ffff;
        }
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

.amount {
  display: flex;
  & > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
  }
  & > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
    color: #333333;
  }
}
.date {
  display: flex;
  align-items: center;
  & > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
    margin-left: 4px;
  }
  & > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
  }
}
.actionIcon {
  color: #4f4f4f;
}
.menuContainer {
  margin-left: -100px !important;
  & > div:not(:last-child) {
    border-bottom: 1px solid #f8f8f8 !important;
  }
}
