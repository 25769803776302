.container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 269px);
  position: relative;

  @media screen and (max-width: 850px) {
		width: calc(100% - 73px);
	}

  @media screen and (max-width: 600px) {
		width: calc(100% - 57px);
	}

  & > hr {
    background-color: #E0E0E0;
  }
}

.pageWrapper {
  // padding: 0 2rem;
  margin-bottom: 4rem;
}

.capitalText {
  text-transform: capitalize;
}

.tableBodyText {
  font-size: .875rem;
  color: #333;
  font-weight: 400;
  cursor: pointer;
}

.tableContainer {
  margin-top: 1.5rem;
  padding: 0 2rem;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;

  @media screen and (max-width: 570px) {
    flex-direction: column;
    align-items: flex-start;
    gap: .75rem;
  }

  & > div {
    & > p {
      color: #333;
      font-size: 1.25rem;
    }
  }
}

.acctContainer {
  display: flex;
  align-items: center;
  gap: 5rem;
  padding: 1rem 2rem;
  border-bottom: 1px solid #E0E0E0;
  margin-bottom: 1.5rem;

  @media screen and (max-width: 1000px) {
    gap: 3rem;
	}

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
	}

  & > p {
    font-weight: 500;
    font-size: 1rem;
    color: #212b36;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 5rem;
    flex-wrap: wrap;
    @media screen and (max-width: 1000px) {
      gap: 3rem;
    }
    @media screen and (max-width: 768px) {
      gap: 1.25rem;
    }

    & > div {
      & > p:first-of-type {
        color: #828282;
        font-size: .75rem;
        font-weight: 400;
      }
  
      & > p:last-of-type {
        color: #333;
        font-size: .875rem;
        font-weight: 500;
      }
    }
  }
}