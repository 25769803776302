.filterModalContainer {
  border: 1px solid #D5DAE1;
  box-shadow: 0px 10px 10px rgba(6, 44, 82, 0.92);
  border-radius: 3px;
  background-color: white;
  max-width: 400px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Roboto', sans-serif;

  & > p {
    font-size: .875rem;
    color: #4F4F4F;
    margin: 1rem 1.5rem;
  }

  hr {
    background-color: #E5E9EC;
  }
}

.modalContent {
  margin: 2rem 1.5rem;
  display: grid;
  grid-gap: 1.25rem;

  input, select {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    width: 100%;
    padding: .5rem .6rem;
  }

  & > div:nth-child(1) {
    & > p {
      color: #333;
      font-size: .875rem;
    }
    & > div {
      display: flex;
      margin-top: .5rem;
      gap: 1rem;
      flex-wrap: wrap;

      & > p {
        border: 1px solid #DDDDDD;
        border-radius: 2px;
        padding: .375rem .75rem;
        color: #828282;
        font-size: .875rem;
      }

      & > p:hover {
        cursor: pointer;
        opacity: .75;
      }
    }
  }

  & > div:nth-child(2) {
    & > p {
      color: #333;
      font-size: .875rem;
    }
    & > div {
      display: flex;
      gap: .5rem;
      align-items: center;

      & > svg {
        color: #25303A;
        margin-top: .5rem;
      }

      & > input {
        padding-left: 0;
        padding-right: 0;
      }

      & > div {
        border: 1px solid #DDDDDD;
        border-radius: 2px;
        padding: .375rem .75rem;
        color: #C4C4C4;
        flex: 1;
        font-size: .75rem;
        margin-top: .5rem;
      }
    }
  }

  & > div:nth-child(3) {
    & > p {
      color: #333;
      font-size: .875rem;
      margin-bottom: .5rem;
    }
  }

  & > div:nth-child(4) {
    & > p {
      color: #333;
      font-size: .875rem;
      margin-bottom: .5rem;
    }
  }
}