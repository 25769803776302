.container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 269px);
  position: relative;

  @media screen and (max-width: 850px) {
		width: calc(100% - 73px);
	}

  @media screen and (max-width: 600px) {
		width: calc(100% - 57px);
	}

  & > hr {
    background-color: #E0E0E0;
  }
}

.pageWrapper {
  padding: 1rem 0;
  margin-bottom: 4rem;
}

.topSection {
  & > div:nth-child(1) {
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
    
    p {
      font-weight: 500;
      font-size: .75rem;
      color: #828282;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
  }

  & > div:nth-child(2) {
    padding: 2rem 2rem 1rem;
    color: #7c7c7c;
    font-weight: 400;
    border-bottom: 1px solid #e0e0e0;
    font-size: .75rem;
  }

  & > div:nth-child(3) {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    gap: 6rem;
    flex-wrap: wrap;

    @media screen and (max-width: 1000px) {
      gap: 3rem;
    }

    @media screen and (max-width: 400px) {
      gap: 1.5rem;
    }

    & > div {
      border-left: 1px solid #e0e0e0;
      padding-left: 6rem;
      @media screen and (max-width: 1000px) {
        border-left: none;
        padding-left: 0;
      }
  
      & > p:first-of-type {
        color: #828282;
        font-size: .75rem;
        font-weight: 400;
      }
  
      & > p:last-of-type {
        color: #333;
        font-size: .875rem;
        font-weight: 500;
      }
    }
  
    & > div:first-of-type {
      border-left: none;
      padding-left: 0;
    }
  }
}

.tableSection {
  padding: 1.5rem 2rem;

  & > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & >p:nth-child(1) {
      font-weight: 400;
      font-size: 1.25rem;
      color: #333;
    }
  }
}

.tableContainer {
  margin-top: 1rem;
}

.tableBodyText {
  font-size: .875rem;
  color: #333;
  font-weight: 400;
  cursor: pointer;
}

.tableBodyCapital {
  font-size: .875rem;
  color: #333;
  font-weight: 400;
  cursor: pointer;
  text-transform: capitalize;
}

.tableBodySpan {
  color: #828282;
}

.dueText {
  font-weight: 700;
  color: #eb5757;
  font-size: .875rem;
  text-transform: capitalize;
}

.pendingText {
  font-size: .875rem;
  color: #12122c;
  font-weight: 500;
  cursor: pointer;
  padding: .2rem 1rem;
  background-color: #f2c94c;
  border-radius: 2px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
}

.wonText {
  font-size: .875rem;
  color: white;
  font-weight: 500;
  cursor: pointer;
  padding: .2rem 1rem;
  background-color: #27ae60;
  border-radius: 2px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
}

.lostText {
  font-size: .875rem;
  color: white;
  font-weight: 500;
  cursor: pointer;
  padding: .2rem 1rem;
  background-color: #eb5757;
  border-radius: 2px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
}

.filterModalContainer {
  border: 1px solid #D5DAE1;
  box-shadow: 0px 10px 10px rgba(6, 44, 82, 0.92);
  border-radius: 3px;
  background-color: white;
  max-width: 400px;
  max-height: 470px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Roboto', sans-serif;

  & > p {
    font-size: .875rem;
    color: #4F4F4F;
    margin: 1rem 1.5rem;
  }

  hr {
    background-color: #E5E9EC;
  }
}

.modalContent {
  margin: 2rem 1.5rem;
  display: grid;
  grid-gap: 1.25rem;

  & > div:nth-child(1) {
    & > p {
      color: #333;
      font-size: .875rem;
    }
    & > div {
      display: flex;
      margin-top: .5rem;
      gap: 1rem;

      & > p {
        border: 1px solid #DDDDDD;
        border-radius: 2px;
        padding: .375rem .75rem;
        color: #828282;
        font-size: .875rem;
      }

      & > p:hover {
        cursor: pointer;
        opacity: .75;
      }
    }
  }

  & > div:nth-child(2) {
    & > p {
      color: #333;
      font-size: .875rem;
    }
    & > div {
      display: flex;
      gap: 1rem;
      align-items: center;

      & > svg {
        color: #25303A;
        margin-top: .5rem;
      }

      & > div {
        border: 1px solid #DDDDDD;
        border-radius: 2px;
        padding: .375rem .75rem;
        color: #C4C4C4;
        flex: 1;
        font-size: .75rem;
        margin-top: .5rem;
      }
    }
  }

  & > div:nth-child(3) {
    & > p {
      color: #333;
      font-size: .875rem;
      margin-bottom: .5rem;
    }
  }

  & > div:nth-child(4) {
    & > p {
      color: #333;
      font-size: .875rem;
      margin-bottom: .5rem;
    }
  }
}