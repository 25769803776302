.container {
  height: auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.currencies {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 56px;
  & > ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    & > a {
      margin-right: 26px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #828282;
      transition: all 0.5s ease;
      &.active {
        font-weight: bold;
        color: #27ae60;
      }
    }
  }
  & > button {
    background: #e0e0e0;
    border-radius: 4px;
    width: auto;
    min-width: 115px;
    height: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px;
    line-height: 8px !important;
    display: flex;
    align-items: center;
    color: #333333;
  }
}
.panel {
  height: auto;
  min-height: 86px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  padding: 22px 56px;
  & > div:not(:first-child) {
    border-left: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.tableContainer {
  padding: 25px 56px;
  .tableHeader {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > h2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #333333;
    }
    & > div {
      display: flex;
      align-items: center;
      & > button {
        padding: 8px 16px;
        width: auto;
        min-width: fit-content;
        height: 32px;
        left: 857px;
        top: 0px;
        background: #e0e0e0;
        border-radius: 4px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.0024px;
        color: #333333;
        margin: 0px 4px;
        display: flex;
        align-items: center;
        &:last-child {
          background: #27ae60;
          color: #ffff;
        }
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
